import React from "react"
import { FiArrowRight } from "react-icons/fi"
import { useNavigate } from 'react-router-dom';
import Contact from "./Contact";
import About from "./About";

const Home = () => {
    const navigate = useNavigate();

    const handleKnowMoreClick = () => {
      navigate('/about'); 
    };
    return (
        <>
        
        <div className="home-container">
            <div className="home-banner-container">
                <div className="home-text-section">
                    <h1 className="primary-heading">
                        Empowering Innovation Through Cutting-Edge Technology
                    </h1>
                    <p className="primary-text">
                        We provide innovative tech solutions that drive your
                        business forward.
                    </p>
                    <button className="secondary-button"  onClick={handleKnowMoreClick}>
            Know more <FiArrowRight />{" "}
          </button>
                </div>
                <div className="home-image-section">
                    <img src="/world.svg" alt="" />
                </div>
            </div>
        </div>
        <About/>
        <Contact/>
        </>
    )
}

export default Home
