import { FiArrowRight } from "react-icons/fi"
import { useNavigate } from 'react-router-dom';
export const AboutUs = () =>{
    const navigate = useNavigate();

    const handleKnowMoreClick = () => {
      navigate('/contact-us'); 
    };
    return(
        <section className="aboutSection">
          <title>About Us - AIFlux Innovations | Empowering Innovation</title>
          <meta name="description" content="Learn more about AIFlux Innovations, our mission, values, and the cutting-edge technology solutions we provide to empower businesses worldwide." />
          <h1 className="paraAboutHeading">Empowering Rural Bharat for a Sustainable Future</h1>
          <p className="paraDescAbout">
          Welcome to Ai Flux Innovations LLP, where we are dedicated to unlocking the potential of Rural India. With the majority of India's population residing in rural areas, agriculture and allied sectors are the lifeblood of Bharat's economy. Yet, a lack of proper data infrastructure and resource mapping has created significant income disparities, hindering growth. </p>
<p className="abouthead">Our Mission</p> 
<p className="paraDescAbout" > 
At Ai Flux, we are committed to bridging this gap by developing sustainable systems and frameworks tailored to the unique needs of rural communities. Our focus is on enhancing agriculture, manufacturing, and service sectors to drive equitable economic development across Rural Bharat.
    </p>
<p className="abouthead"> Our Approach</p> 
<ul style={{paddingLeft:"2rem"}}>
    <li >
        <p className="abouthead">
        Understanding Rural Dynamics:
        </p>
        <p className="paraDescAbout">

    We start by deeply understanding the social structures and existing workflows of rural communities to create solutions that are relevant and effective.
        </p>
    </li>
    <li>
    <p className="abouthead">Building Trust:</p>
    <p className="paraDescAbout">

     We prioritize building trust with rural populations, ensuring that our solutions are transparent and aligned with their interests.
    </p>
    </li>
    <li>
<p className="abouthead">Seamless Integration:</p>
<p className="paraDescAbout">

 Our technologies are designed for easy adoption, minimizing the learning curve and enabling swift integration into everyday practices.
</p>
    </li>
</ul>


<p className="abouthead">Challenges We Address</p>
<ul style={{paddingLeft:"2rem"}}>
    <li>
    <p className="abouthead">Overcoming Distrust:</p> 
    <p className="paraDescAbout">
        We work to foster genuine relationships and provide accurate, reliable data to build confidence in our solutions.
        </p> 
    </li>
    <li>
        <p className="abouthead">

        Bridging Urban-Rural Divides: 
        </p>
        <p className="paraDescAbout">

        We address concerns of exploitation by ensuring that our systems empower rather than exploit rural communities.
        </p>
    </li>
    <li>
        <p className="abouthead">

        Simplifying Technology Adoption:
        </p>
        <p className="paraDescAbout">

        Our user-friendly solutions are crafted to be intuitive, making the transition to new technologies as seamless as possible.
        </p>
    </li>
</ul>
<p  className="abouthead">

Join Us in Shaping the Future
</p>
<p className="paraDescAbout" style={{paddingBottom:"1rem"}}>
Ai Flux Innovations is at the forefront of transforming Rural Bharat. Together, we can create a sustainable, prosperous future where every village thrives. Explore our solutions and join us in empowering rural communities across India.
</p>
         
<button className="secondary-button" onClick={handleKnowMoreClick}>
Contact Us <FiArrowRight />{" "}
          </button>
        </section>
    )
}