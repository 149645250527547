import React from "react"
import AboutBackgroundImage2 from "../Assets/old_man2.png"

const About = () => {

    return (
        <div className="about-section-container">
            <div className="about-background-image-container">
                <img src="/bg.svg" alt="" />
            </div>
            <div className="about-section-image-container" >
                <img src={AboutBackgroundImage2} alt="" loading="lazy" />
            </div>
            <div className="about-section-text-container" >
                <p className="primary-heading" style={{paddingTop:"20px"}}>About</p>
                <h1 className="primary-subheading">
                    Empowering Rural India's Future
                </h1>
                <p className="primary-text">
                    AiFlux Innovations is dedicated to creating sustainable
                    solutions for agriculture, manufacturing, and services in
                    Rural Bharat.
                </p>
                <p className="primary-text">
                    By understanding local needs and overcoming challenges, we
                    develop user-friendly technologies that bridge the gap,
                    fostering equitable growth and prosperity in India's
                    heartland.
                </p>
            </div>
        </div>
    )
}

export default About
