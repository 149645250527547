import React, {useState,useEffect}  from "react";
import "./App.css";
import Home from "./Components/Home";
import Contact from "./pages/contact-us";
import Footer from "./Components/Footer";
import Navbar from "./Components/Navbar";
import { BrowserRouter as Router, Routes, Route,useLocation } from 'react-router-dom';
import { AboutUs } from "./pages/about";
import { Blog } from "./pages/blog";
import Loading from "./Components/Loading";
function App() {

  return (
    <Router>
         
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  useEffect(() => {
    const handleRouteChange = () => {
      setLoading(true);
      setTimeout(() => setLoading(false), 500); // Simulate loading time
    };

    handleRouteChange(); // Trigger loading when the component mounts or location changes

    return () => {
      setLoading(false); // Clean up on component unmount
    };
  }, [location]);


  return (
    <div className="App">
         {loading && <Loading />}
      <Navbar
      />
      <Routes>
        <Route path="/about" element={<AboutUs />} />
        <Route path="/" element={<Home/>} />
        <Route path="/contact-us" element={<Contact/>} />
        <Route path="/blog" element={<Blog/>} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
