import React from "react";
import { IoMailOpenOutline } from "react-icons/io5"
import { Link } from "@mui/material"

const Contact = () => {

  return (
    <div className="contact-page-wrapper">
      <h1 className="primary-heading">Happy To Help!!!</h1>
      <div
                        style={{
                            display: "flex",
                         gap:"10px",
                         flexDirection:"column",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingTop:"1rem"
                        }}
                    >
                        <IoMailOpenOutline
                            style={{ fontSize: "2rem", color: "#00b102" }}
                        />
                        <p style={{ fontSize: "1rem", color:  "#00b102" }}>
                            Email Us At
                        </p>
                        <Link
                            href="mailto:aiflux2024@gmail.com"    
                            style={{ fontSize: "1rem" }}  
                        >
                            aiflux2024@gmail.com
                        </Link>
                    </div>

    </div>
  );
};

export default Contact;
