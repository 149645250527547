import { MdOutlineSupportAgent } from "react-icons/md"
import { IoMailOpenOutline } from "react-icons/io5"
import { TbMap2 } from "react-icons/tb"
import AOS from "aos"
import "aos/dist/aos.css"
import { useEffect } from "react"
import { Link } from "@mui/material"
import React from "react"

const Contact = () => {
    useEffect(() => {
        AOS.init({
            offset: 100,
        })
    }, [])

    return (
        <div
            style={{
                background: "#F8F8F8",
                padding: "3vw",
                paddingBottom: "0px",
                backgroundColor:"#fdfdf0",
                minHeight:"70vh",
                height:"auto",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "3rem",
                }}
            >
                <h1
                    style={{
                        color: "#000",
                        fontSize: "2rem",
                        fontWeight: "bold",
                    }}
                >
                    Get In <span style={{ color: "#00b102" }}>Touch</span>
                </h1>
            </div>
            <div
                style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "1rem",
                    justifyContent: "space-evenly",
                    paddingBottom: "2rem",
                }}
            >
                <div
                    style={{
                        width: "360px",
                        height: "100px",
                        padding: "1rem",
                        backgroundColor: "#00b102",
                        borderRadius: "1rem",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <MdOutlineSupportAgent
                            style={{ fontSize: "2rem", color: "white" }}
                        />
                        <p style={{ fontSize: "1rem", color: "white" }}>Call</p>
                        <Link
                            href="tel:+919864151819"
                          style={{color:"white"}}
                        >
                            9864151819
                        </Link>
                    </div>
                </div>
                <div
                    style={{
                        width: "360px",
                        height: "100px",
                        padding: "1rem",
                        backgroundColor: "#00b102",
                        borderRadius: "1rem",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <IoMailOpenOutline
                            style={{ fontSize: "2rem", color: "white" }}
                        />
                        <p style={{ fontSize: "1rem", color: "white" }}>
                            Email Us
                        </p>
                        <Link
                            href="mailto:aiflux2024@gmail.com"
                            style={{color:"white"}}
                        >
                            aiflux2024@gmail.com
                        </Link>
                    </div>
                </div>
                <div
                    style={{
                        width: "360px",
                        height: "100px",
                        padding: "1rem",
                        backgroundColor: "#00b102",
                        borderRadius: "1rem",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <TbMap2
                            style={{ fontSize: "2rem", color: "white" }}
                        />
                        <p style={{ fontSize: "1rem", color: "white" }}>
                            Location
                        </p>
                        <p style={{ fontSize: "1rem", color: "white" }}>
                            Guwahati-781028
                        </p>
                    </div>
                </div>
            </div>          
        </div>
    )
}

export default Contact
