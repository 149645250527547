/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState,useEffect } from "react"
import { HiOutlineBars3 } from "react-icons/hi2"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import List from "@mui/material/List"
import Divider from "@mui/material/Divider"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import HomeIcon from "@mui/icons-material/Home"
import InfoIcon from "@mui/icons-material/Info"
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded"
import { useNavigate } from 'react-router-dom';
import Logo from "../Assets/logo.png"

const Navbar = () => {
    const [openMenu, setOpenMenu] = useState(false)
    const navigate = useNavigate();
    const handleHomeClick = () => {
      navigate('/'); 
    };
    const handleAboutClick = () => {
        navigate('/about'); 
      };
      const handleContactClick = () => {
        navigate('/contact-us'); 
      };
    const menuOptions = [
        {
            text: "Home",
            icon: <HomeIcon />,
            onClick: () => handleHomeClick(),
        },
        {
            text: "About",
            icon: <InfoIcon />,
            onClick: () => handleAboutClick(),
        },
        {
            text: "Contact",
            icon: <PhoneRoundedIcon />,
            onClick: () => handleContactClick(),
        },
    ]

    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 50) {
          setIsScrolled(true);
        } else {
          setIsScrolled(false);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (
        <nav
            className={`navbar ${isScrolled ? 'scrolled' : ''}`}
        >
            <div className="nav-logo-container" style={{cursor:"pointer"}}>
                <a href="/">
                <img src={Logo} alt="logo" />
                </a>
            </div>
            <div className="navbar-links-container">
                <a
                  href="/"
                    style={{ cursor: "pointer" }}
                >
                    Home
                </a>
                <a
                   href="/about"
                    style={{ cursor: "pointer" }}
                >
                    About
                </a>
                <a
                  href="/contact-us"
                    style={{ cursor: "pointer" }}
                >
                    Contact
                </a>
            </div>
            <div className="navbar-menu-container">
                <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
            </div>
            <Drawer
                open={openMenu}
                onClose={() => setOpenMenu(false)}
                anchor="right"
            >
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={() => setOpenMenu(false)}
                    onKeyDown={() => setOpenMenu(false)}
                >
                    <List>
                        {menuOptions.map((item) => (
                            <ListItem key={item.text} disablePadding>
                                <ListItemButton onClick={item.onClick}>
                                    <ListItemIcon style={{color:"#00b102"}}>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.text} />
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                    <Divider />
                </Box>
            </Drawer>
        </nav>
    )
}

export default Navbar
