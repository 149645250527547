import React from "react"
import { FaLinkedin, FaRegCopyright } from "react-icons/fa"
import { LuPhone } from "react-icons/lu"
import { MdOutlineEmail } from "react-icons/md"

const Footer = () => {
    return (
        <footer className="footer-wrapper" style={{color:"#006537"}}>
            <div className="footer-section-two">
                {/* <div className="footer-section-columns"> */}
                <a href="https://www.linkedin.com/company/ai-flux-innovations-llp/about/">
                    <FaLinkedin
                        style={{ fontSize: "3rem", color: "#0072b1" }}
                    />
                </a>

                <span>
                    <LuPhone
                        style={{
                            fontSize: "1.2rem",
                            marginRight: "0.3rem",
                            marginBottom: "-0.2rem",
                        }}
                    />
                    +91 98641 51819
                </span>
                <span>
                    <MdOutlineEmail
                        style={{
                            fontSize: "1.3rem",
                            marginRight: "0.3rem",
                            marginBottom: "-0.2rem",
                        }}
                    />
                    aiflux2024@gmail.com
                </span>
                <span>
                    <FaRegCopyright
                        style={{ fontSize: "0.8rem", marginRight: "0.2rem" }}
                    />
                    Copyright 2024 AiFlux
                </span>
                {/* <span>Terms & Conditions</span > */}
                {/* <span>Privacy Policy</span> */}
                {/* </div> */}
            </div>
        </footer>
    )
}

export default Footer
